<template>
  <VContainer
    fluid
    py-0
    class="dashboard-container"
  >
    <VRow
      class="pa-4"
      justify-space-between="justify-space-between"
    >
      <VCol
        sm="12"
        md="5"
        lg="4"
      >
        <VRow>
          <VCol
            shrink
            class="mt-2"
          >
            <VBtn
              text
              icon
              button-classes="ml-3"
              @click="navigateTo('report.index')"
            >
              <VIcon>
                arrow_back
              </VIcon>
            </VBtn>
          </VCol>
          <VCol
            v-show="!editDashboardLabel && form.id"
            fill-height
            class="pl-0 mt-2"
          >
            <div class="d-flex align-items-center">
              <span class="pl-2 title">{{ form.title }}</span>
              <VTooltip>
                <template #activator="{on}">
                  <VBtn
                    v-on="on"
                    icon
                    @click="onToggleEditDashboard"
                  >
                    <VIcon color="primary">
                      mdi-pencil
                    </VIcon>
                  </VBtn>
                </template>
                <span>Edit Dashboard</span>
              </VTooltip>
              <VTooltip>
                <template #activator="{on}">
                  <VBtn
                    v-on="on"
                    icon
                    :loading="activity.isDeleting"
                    @click="onDeleteDashboard"
                  >
                    <VIcon color="error">
                      mdi-delete
                    </VIcon>
                  </VBtn>
                </template>
                <span>Delete Dashboard</span>
              </VTooltip>
            </div>
          </VCol>
          <VCol
            v-show="editDashboardLabel || !form.id"
            grow
            class="mt-2 pl-0"
          >
            <TextField
              v-model="form.title"
              single-line
              autofocus
              label="Dashboard Title"
              ref="dashboardTitle"
              placeholder="Enter dashboard title..."
              style="min-height: 52px"
              :error="$v.form.title.$error"
              :clearable="(isFormReady || form.id) && !activity.isSavingTitle"
              :loading="activity.isFormLoading"
              :disabled="activity.isFormLoading"
              @input="$v.form.title.$touch()"
              @keydown.enter="onSaveDashboard"
              @blur="$v.$touch()"
            >
              <template #append>
                <VTooltip>
                  <template #activator="{on}">
                    <VIcon
                      v-on="on"
                      class="d-flex flex-row-reverse"
                      :color="activity.isFormLoading ? 'disabled' : 'primary'"
                      :loading="activity.isFormLoading"
                      :disabled="activity.isFormLoading"
                      @click="onSaveDashboard"
                    >
                      mdi-check
                    </VIcon>
                  </template>
                  <span>Save changes</span>
                </VTooltip>
                <VTooltip>
                  <template #activator="{on}">
                    <VIcon
                      v-on="on"
                      class="d-flex flex-row-reverse"
                      :loading="activity.isFormLoading"
                      :disabled="activity.isFormLoading"
                      @click="onToggleEditDashboard"
                    >
                      mdi-cancel
                    </VIcon>
                  </template>
                  <span>Cancel changes</span>
                </VTooltip>
              </template>
            </TextField>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        sm="12"
        md="7"
        lg="6"
      >
        <VRow>
          <VCol
            grow
            class="d-flex justify-end"
          >
            <VTabs
              fixed-tabs
              background-color="transparent"
              v-model="selectedRange"
              style="max-width: 600px"
              @change="updateSelectedRange"
            >
              <VTab
                v-for="(range, rangeIndex) in ranges"
                :key="rangeIndex"
                @click="onUpdateDateRange(range)"
              >
                {{ range.label }}
              </VTab>
            </VTabs>
          </VCol>
          <VCol
            shrink
            class="d-flex flex-row pt-1"
          >
            <VBtn
              fab
              color="#f0c013"
              class="add-btn mr-1"
              :disabled="!form.id"
              :loading="activity.isLoading"
              @click="onToggleChartsDialog(false)"
            >
              <VIcon color="white">
                mdi-plus
              </VIcon>
            </VBtn>
            <VBtn
              icon
              color="default"
              :disabled="!form.id"
              :loading="activity.isLoading"
              @click="reloadAllData"
            >
              <VIcon color="black">
                mdi-refresh
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="12">
        <VSkeletonLoader
          type="table-cell"
          :loading="activity.isLoading"
        >
          <VRow class="pa-4 pt-0">
            <VCol
              v-for="chart in dashboardReports"
              :key="chart.id"
              :md="chart.config.cols || '4'"
            >
              <VCard
                class="updates-section"
                :tile="chart.pinned_main_dashboard"
                :class="{activeCard: chart.pinned_main_dashboard, rounded: !chart.pinned_main_dashboard }"
              >
                <VCardText class="chartAreaWrapper">
                  <component
                    v-if="shouldShowChart(chart)"
                    :config="chart.config"
                    :chart="chart"
                    :report-data="chart.reporting_data"
                    :is="getChartComponentName(chart.chart_type.name)"
                    :ref="normalizeChartRef(chart.chart_type.name)"
                  />
                  <NoDataChart
                    :chart="chart"
                    v-else
                  />
                </VCardText>
                <VCardActions
                  class="pt-0"
                  style="height: 40px;"
                >
                  <VRow
                    :class="{'col-2-structure': chart.config.cols === 2 }"
                  >
                    <VCol
                      :cols="chart.config.cols === 2
                        ? 2 : 3"
                      class="pr-0"
                      :class="{'order-1': chart.config.cols === 2}"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div
                            :class="{pinnedCard: chart.pinned_main_dashboard}"
                            v-on="!chart.pinned_main_dashboard ? on : null"
                            >
                            <VBtn
                                class="cursor-default"
                              icon
                              height="auto"
                              width="auto"
                              :disabled="chart.pinned_main_dashboard"
                              :loading="enabledDashboard.chartId == chart.id && enabledDashboardLoader"
                              :class="{activeDashboard:chart.pinned_main_dashboard}"
                              @click="onEnableOnDashboard(chart.id)"
                            >
                              <VIcon
                                icon="grid"
                                small
                                :color="chart.pinned_main_dashboard ? `#ffffff !important` : `#e0dede`"
                              >
                                dashboard
                              </VIcon>
                            </VBtn>
                          </div>
                        </template>
                        <span>{{ chart.pinned_main_dashboard ? '' : 'Pin Report' }}</span>
                      </v-tooltip>
                    </VCol>
                    <VCol
                      cols="5"
                      justify="center"
                      align="center"
                      class="text-truncate"
                      :class="{'order-0': chart.config.cols === 2, 'col-12': chart.config.cols === 2}"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <strong
                            class="chart-title"
                            v-on="on"
                          >{{ chart.title ?? 'for now' }}</strong>
                        </template>
                        <span v-if="chart.reporting_data.length > 0">{{ chart.reporting_data[0].interval_start }} - {{
                          chart.reporting_data[0].interval_end
                        }}</span>
                      </v-tooltip>
                    </VCol>
                    <VCol
                      cols="4"
                      class="d-flex flex-row-reverse pl-0"
                      :class="{'order-2': chart.config.cols === 2}"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <VBtn
                              icon
                              height="auto"
                              width="auto"
                              :loading="enabledDashboard.chartId == chart.id && activity.isDeleting || chart.isLoading"
                              @click="onUpdateChartData(chart)"
                              v-on="on"
                          >
                            <VIcon small>
                              mdi-refresh
                            </VIcon>
                          </VBtn>
                        </template>
                        <span>Refresh Data</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <VBtn
                            icon
                            height="auto"
                            width="auto"
                            :loading="enabledDashboard.chartId == chart.id && activity.isDeleting"
                            @click="onDeleteChart(chart)"
                            v-on="on"
                          >
                            <VIcon small>
                              mdi-delete
                            </VIcon>
                          </VBtn>
                        </template>
                        <span>Delete Report</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <VBtn
                            icon
                            height="auto"
                            width="auto"
                            @click="onToggleChartsDialog(false, chart)"
                            v-on="on"
                          >
                            <VIcon small>
                              mdi-table-edit
                            </VIcon>
                          </VBtn>
                        </template>
                        <span>Edit Report</span>
                      </v-tooltip>
                    </VCol>
                  </VRow>
                </VCardActions>
              </VCard>
              <VRow
                class="pl-3 pr-3 "
                v-if="chart.pinned_main_dashboard"
              >
                <VCol
                  align="center"
                  class="dashboard-active-hint py-3 px-0 text-uppercase"
                >
                  Will be displayed on the home page
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VSkeletonLoader>
      </VCol>
    </VRow>
    <Dialog
      persistent
      :is-dialog-open="showAddNewChartDialog"
      :hide-overlay="true"
      :max-width="`90%`"
      :card-text-class="{'px-0': true}"
      @toggleDialog="onToggleChartsDialog(false)"
    >
      <template #title>
        <div
          class="pr-2"
          v-if="enablePrevButton"
        >
          <VBtn
            depressed
            color="#f2f2f2"
            @click="prevStep"
          >
            Back
          </VBtn>
        </div>
        {{ report.exists ? `Edit Report` : `Add New` }}
      </template>
      <template #actions>
        <div
          class="pr-2"
          v-if="enableNextButton"
        >
          <VBtn
            depressed
            color="info"
            @click="nextStep"
          >
            Next
          </VBtn>
        </div>
        <VBtn
          depressed
          color="#f2f2f2"
          @click="onToggleChartsDialog(false)"
        >
          {{ report instanceof Object && report.id ? 'Close' : 'Cancel' }}
        </VBtn>
        <div
          class="pl-2"
          v-if="enableSaveButton"
        >
          <VBtn
            depressed
            color="primary"
            :loading="saveChartLoader"
            :disabled="!isSaveReady"
            @click="saveChart"
          >
            Save
          </VBtn>
        </div>
      </template>
      <ChartSave
        v-if="showAddNewChartDialog"
        ref="saveReport"
        :step="step"
        :dashboard-id="form.id"
        :report="report"
        @saved="onSavedReport"
        @showNextBtn="showNextButton"
        @toggleChartDialog="onToggleChartsDialog(true)"
        @toggleSaveBtn="onToggleSaveBtn"
        @toggleFormLoading="onToggleFormLoading"
      />
    </Dialog>
    <Confirm ref="confirmDelete" />
  </VContainer>
</template>

<script>
import ChartSave from "./Save";
import utils from "@nitm/js-api-client-base/dist/store/utils";
import { mapActions } from "vuex";
import HasActivityMixin from "@/mixins/Activity";
import DeletesItems from "@/mixins/DeletesItems";
import FormMixin from "@/mixins/Form";
import SupportsCharts from "@/mixins/SupportsCharts";
import { required } from "vuelidate/lib/validators";
import NoDataChart from '@/components/Reports/Charts/NoDataChart';

export default {
  name: "ReportDashboard",
  components: {
    ChartSave,
    NoDataChart
  },
  mixins: [HasActivityMixin, FormMixin, DeletesItems, SupportsCharts],
  data() {
    return {
      enabledDashboard: {
        type: "",
        chartId: "",
        selected: false,
      },
      showAddNewChartDialog: false,
      enablePrevButton: false,
      enableNextButton: false,
      enableSaveButton: false,
      step: 1,
      editDashboardLabel: false,
      enabledDashboardLoader: false,
      saveChartLoader: false,
      dashboardReportCount: 0,
      activity: {
        isSavingTitle: false,
        isSavingDashboard: false,
        isDeleting: false,
      },
      isSaveReady: false,
      defaultForm: {
        is_available_to_team: true,
      },
      report: {
        exists: false,
        id: null,
        data: null
      },
      filter: {
        start: 6,
        start_type: 'months'
      },
      selectedRange: 3,
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  computed: {
    dashboardReports: {
      get() {
        return this.form.reports || [];
      },
      set(charts) {
        this.form.reports = charts;
      }
    },
    ranges() {
      // NOTE: unit should always be plural, that's how the API processes labels
      return [
        {
          label: "7 Days",
          unit: 'days',
          value: 7
        },
        {
          label: "4 Weeks",
          unit: "weeks",
          value: 4,
        },
        {
          label: "3 Months",
          unit: "months",
          value: 3,
        },
        {
          label: "6 Months",
          unit: "months",
          value: 6,
        },
        {
          label: "1 Year",
          unit: "months",
          value: 12,
        },
      ];
    },
    realDashboardId() {
      return this.dashboardId || this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      doSaveReport: "report/save",
      doSaveFilters: "reporting/setFilters",
      doGetDashboard: "reportDashboard/getOne",
      doSaveDashboard: "reportDashboard/save",
      doPinReportDashboard: "reportDashboard/pin",
      doDeleteDashboard: "reportDashboard/delete",
      doDeleteDashboardItem: "reporting/delete",
      doGetDashboardItem: "reportDashboardItem/getOne",
    }),
    async reloadAllData() {
      this.dashboardReportCount = this.dashboardReports.length;
      if (!this.dashboardReportCount) {
        return;
      }

      this.$emit("startLoading");
      await this.dashboardReports.map(async (report, index) => {
        this.$set(report, "isLoading", true);
        await this.doGetDashboardItem({
          id: report.id,
          report_dashboard_id: this.realDashboardId,
          delta_time: this.filter.start,
          delta_time_type: this.filter.start_type,
        }).then((response) => {
          this.$delete(response, "isLoading");
          this.$delete(report, "isLoading");
          response.isLoading = false;
          utils.updateStateData(this.dashboardReports, response);
          this.$bus.$emit(
            "notificationMessage",
            "Dashboard reports successfully regenerated!"
          );
          return response;
        });
        this.$emit("doneLoading");
      })
    },
    onEnableOnDashboard(chartId) {
      this.onPinReportDashboard(chartId);
    },
    onToggleChartsDialog(refresh, chart = null) {
      this.saveChartLoader = false;
      this.showAddNewChartDialog = !this.showAddNewChartDialog;
      this.enableNextButton = false;
      this.enablePrevButton = false;
      this.enableSaveButton = false;
      this.step = 1;
      this.report = {
        exists: false,
        id: null,
      };
      if (refresh) {
        this.onGet(this.filter).then(() => {
          this.activity.isDeleting = false;
        });
      }
      if (chart.id) {
        this.nextStep(false);
        this.report = {
          exists: true,
          id: chart.id,
          data: chart
        };
        this.enablePrevButton = false;
      }
    },
    shouldShowChart(chart) {
      return ( this.filter.start !== 1 && chart.reporting_data.length > 0 && this.isSupportedChart(chart.chart_type.name));
    },
    onToggleSaveBtn(status) {
      this.isSaveReady = status;
    },
    onToggleFormLoading(status) {
      console.log("Enable save chart loader?", status);
      this.saveChartLoader = status;
    },
    showNextButton() {
      this.step = 1;
      this.enableNextButton = true;
      this.enableSaveButton = false;
    },
    onToggleEditDashboard() {
      this.editDashboardLabel = !this.editDashboardLabel;
      this.validateModel();
    },
    nextStep(withPrevButton = true) {
      this.step = 2;
      this.enableSaveButton = true;
      this.enableNextButton = false;
      if (withPrevButton) {
        this.enablePrevButton = true;
      }
    },
    prevStep() {
      this.step -= 1;
      this.enablePrevButton = this.step < 1;
    },
    saveChart() {
      this.saveChartLoader = true;
      // this.$refs.saveReport.$refs.report.onSave();
      this.$log.info("Bus Event: Emitting Save Report");
      this.$bus.$emit("save:report");
    },
    onSaveDashboard() {
      return !this.form.id ? this.onCreateDashboard() : this.onUpdateDashboard();
    },
    onCreateDashboard() {
      this.activity.isSavingTitle = true;
      this.saveFormModel(this.doSaveDashboard)
        .then((result) => {
          this.activity.isSavingTitle = false;
          this.editDashboardLabel = false;
          this.form = result;
          this.$router.push({
            name: "report.dashboard",
            params: { id: result.id },
          });
          this.filter.id = result.id;
          this.doSaveFilters(this.filter);
        })
        .catch((error) => {
          this.activity.isSavingTitle = false;
          this.$log.debug("Save dashboard title error!", error);
        });
    },
    onUpdateDashboard() {
      this.activity.isSavingTitle = true;
      this.saveFormModel(this.doSaveDashboard)
        .then((result) => {
          this.activity.isSavingTitle = false;
          this.editDashboardLabel = false;
          this.$bus.$emit(
            "notificationMessage",
            "Dashboard title saved successfully!"
          );
        })
        .catch((error) => {
          this.activity.isSavingTitle = false;
          this.$log.debug("Update dashboard title error!", error);
        });
    },
    // Always return these values to allow for the mixins to work properly!
    onDeleteDashboard() {
      const deleter = () => {
        return this.doDeleteDashboard(this.form.id)
          .then((result) => {
            this.activity.isDeleting = false;
            this.$bus.$emit(
              "notificationMessage",
              "Dashboard deleted successfully!"
            );
            this.$router.push({ name: "report.index" });
          })
          .catch((error) => {
            this.$log.debug("Delete dashboard error!", error);
          });
      };
      return this.onDeleteItem(this.form.id, deleter);
    },
    // Always return these values to allow for the mixins to work properly!
    onGet(id) {
      this.$emit("startLoading");
      let params = Object.assign({id: id}, this.filter)
      return this.doGetDashboard(params)
        .catch((error) => {
          if (error.code == 404) {
            this.$router.push({ name: "report.index" });
          }
          this.$log.debug("Dashboard reports error!", error);
        })
        .then((result) => {
          this.$emit("doneLoading");
          this.form = result;
          return result;
        });
    },
    // Always return these values to allow for the mixins to work properly!
    onPinReportDashboard(chartId) {
      this.enabledDashboard.chartId = chartId;
      this.enabledDashboardLoader = true;
      let params = {
        report_dashboard_id: this.form.id,
        report_id: chartId,
      };
      return this.doPinReportDashboard(params)
        .then((result) => {
          this.$bus.$emit("notificationMessage", "Report pinned successfully!");
          this.dashboardReports.map((chart) => {
            chart.pinned_main_dashboard = chart.id == chartId;
          });
          this.enabledDashboardLoader = false;
          this.enabledDashboard.selected = true;
        })
        .catch((error) => {
          this.$log.debug("Pin report dashboard error!", error);
        });
    },
    // Always return these values to allow for the mixins to work properly!
    onDeleteChart(item) {
      return this.onDeleteItem(item).then((status) => {
        if (status) {
          this.activity.isDeleting = false;
          this.enabledDashboard.chartId = null;
          this.$bus.$emit(
            "notificationMessage",
            "Report deleted successfully!"
          );
        }
      });
    },
    // Always return these values to allow for the mixins to work properly!
    onUpdateChartData(item) {
      this.$set(item, "isLoading", true);
      return this.doGetDashboardItem({
        id: item.id,
        report_dashboard_id: this.realDashboardId,
        delta_time: this.filter.start,
        delta_time_type: this.filter.start_type,
      }).then((status) => {
        this.$delete(item, "isLoading");
        this.onGet(this.filter);
      });
    },
    onDelete(item) {
      this.enabledDashboard.chartId = item.id;
      this.activity.isDeleting = true;
      let params = {
        id: item.id,
        report_dashboard_id: this.form.id,
      };
      return this.doDeleteDashboardItem(params).then(() => {
        utils.removeFromStateData(this.form.reports, item);
        this.activity.isDeleting = false;
      });
    },
    onUpdateDateRange: function (range) {
      this.$log.info("Updating dashboard date range", range);
      if (range.value) {
        console.log(this.$timezone, this.$timezone.moment());
        // this.filter.start = this.$timezone
        //   .moment()
        //   .subtract(range.value, range.unit)
        //   .format("YYYY-MM-DD HH:mm");
        // this.filter.end = this.$timezone.moment().format("YYYY-MM-DD HH:mm");
        this.filter.start = range.value;
        this.filter.start_type = range.unit;
        let query = { ...this.$route.query, ...{selectedRange: this.selectedRange} };
        this.$router.replace({query})
      } else {
        this.filter.start = null;
        this.filter.start_type = null;
        this.filter.end = null;
      }
      this.doSaveFilters(this.filter);
      this.onGet(this.filter);
    },
    onSavedReport(report) {
      this.$log.info("Adding saved report", report, utils);
      utils.addToStateData(this.form.reports, report);
      this.$bus.emit()
      this.onToggleChartsDialog(true);
    },
    updateSelectedRange(val) {
      console.trace('Watching selectedRange', val);
      let query = { ...this.$route.query, ...{selectedRange: this.selectedRange} };
      this.$router.replace({query})
    }
  },
  beforeMount() {
    // let queryString = window.location.search;
    // let urlParams = new URLSearchParams(queryString);
    // console.log('Selected range', urlParams.get('selectedRange'))
    // this.selectedRange = urlParams.get('selectedRange');
  },
  mounted() {
    this.filter.id = this.realDashboardId;
    this.doSaveFilters(this.filter);
    this.$bus.$on("toggle:dialog", () => {
      this.$log.info("Bus Event: Toggle Charts Dialog", this.form);
      this.onToggleChartsDialog(true);
    });
  },
};
</script>

<style lang="scss">
.updates-section {
  box-shadow: -0.5px 1.4px 6.1px 0.4px rgba(0, 0, 0, 0.03) !important;
}
.dashboard-container {
  .add-btn {
    width: 37px;
    height: 37px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 #fcb27b;
  }
  .v-tabs-bar {
    padding: 0px !important;
  }
  .fit-content {
    height: fit-content;
  }
  .chartAreaWrapper {
    width: 100%;
    overflow-x: auto;

    > .row {
      margin: 0px 12px;
    }
  }
  .programs-chart {
    width: 500px;
  }
  .activeCard {
    background-color: #7479db;
    border: solid 2px #7479db;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    .v-card__text,
    .v-card__actions {
      background-color: #fff;
    }

    .v-card__actions {
      border-radius: 0 0 5px 5px !important;
    }
  }
  .activeChart {
    background-color: #7479db;
    border-radius: 6px;
  }
  .activeDashboard {
  }
  .dashboard-active-hint {
    font-size: 8px;
    background-color: #7479db;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
  }
  .center-label {
    font-size: 10px;
    width: 60px;
    top: 72px;
    position: absolute;
    left: 65px;
    line-height: 15px;
  }
  .chart-title {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
  }
  .pinnedCard {
    padding-top: 4px;
    bottom: 0;
    position: absolute;
    background-color: #7479db;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;

    button {
      background-color: transparent !important;
    }
  }
}
.custom-dialog .v-card .v-card__title {
  height: auto;
  flex-basis: 55px !important;
}

.max-w-full {
  max-width: 100%;
}

.col-2-structure {
  overflow: hidden;
  //max-width: 100%;

  .order-0 {
    padding-bottom: 0
  }

  .order-0, .order-1, .order-2 {
    padding-top: 0;
  }

  .order-2 {
    margin-left: auto;
  }
}
</style>
